import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const EditPackagePrice = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [parks, setParks] = useState([]);
  const [packages, setPackages] = useState([]);
  const [ppPriceId, setPPPriceId] = useState(props.prices.per_person_price_id);
  const [personCount, setPersonCount] = useState(props.prices.person_count);
  const [prices, setPrices] = useState(props.prices.pp_price);
  const [parkId, setParkId] = useState(props.prices.park_id);
  const [packageId, setPackageId] = useState(props.prices.package_id);

  useEffect(() => {
    parkData();
    packageData();
  }, []);

  const parkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };
  const packageData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/package/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const editPrice = async (e) => {
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      per_person_price_id: props.prices.per_person_price_id,
      package_id: packageId,
      park_id: parkId,
      person_count: personCount,
      pp_price: prices,
    };

    await axios
      .post(url + "/api/price/edit", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getPackagePriceData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Edit Package Price
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={editPrice} id="myForm">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Park Name
              </label>

              <select
                className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-1/2"
                onChange={(e) => {
                  setParkId(e.target.value);
                }}
                value={parkId}
              >
                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Package Name
              </label>

              <select
                className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-1/2"
                onChange={(e) => {
                  setPackageId(e.target.value);
                }}
                value={packageId}
              >
                {packages.map((pack) => {
                  return <option value={pack.id}>{pack.package_name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Person Count
              </label>
              <select
                className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-1/2"
                onChange={(e) => {
                  setPersonCount(e.target.value);
                }}
                value={personCount}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
              </select>
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Per Person Price
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="number"
                required
                onChange={(e) => {
                  setPrices(e.target.value);
                }}
                value={prices}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditPackagePrice;
