import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useStateContext } from "../contexts/ContextProvider";

const NewAbout = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [contentTypeId, setContentTypeId] = useState(1);
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);

  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    loadAllAboutData();
  }, []);

  const loadAllAboutData = async () => {
    const bodyParameters = {
      status: 1,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .post(url + "/api/about/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          response.data.output.forEach((data) => {
            if (data.content_type_id === "1") {
              setId(data.id);
              setTitle(data.title);
              setDescription(data.description);
            }
          });
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const addAbout = async (e) => {
    e.preventDefault();

    const form = document.forms.myForm;
    const formData = new FormData(form);
    const title = formData.get("title-value");
    const description = formData.get("desc-value");

    let data = new FormData();
    data.append("about_id", id);
    data.append("title", title);
    data.append("description", description);
    data.append("content_type_id", contentTypeId);
    data.append("about_image", file);
    data.append("file_extention", fileExtension);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "/api/about/edit", data, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getAboutData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };

  const changeContentType = (id) => {
    setContentTypeId(id);
    data.forEach((det) => {
      if (det.content_type_id === id) {
        setId(det.id);
        setTitle(det.title);
        setDescription(det.description);
      }
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Update About Us Page Content
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={addAbout} id="myForm">
          <div className="flex flex-row -mx-3 mb-6 mt-4">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Content Type
              </label>
              <select
                className="appearance-none p-2 block text-black border border-gray-200 rounded-lg leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 w-full"
                onChange={(e) => {
                  changeContentType(e.target.value);
                }}
                value={contentTypeId}
              >
                <option value={1}>About Us</option>
                <option value={2}>Vision</option>
                <option value={3}>Mission</option>
              </select>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                File
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*,video*/"
                onChange={fileHandler}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Title <label className="text-red-600">*</label>
              </label>
              <RichTextEditorComponent
                id="title"
                htmlAttributes={{ name: "title-value" }}
                value={title}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description <label className="text-red-600">*</label>
              </label>
              <RichTextEditorComponent
                id="desc"
                htmlAttributes={{ name: "desc-value" }}
                value={description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NewAbout;
