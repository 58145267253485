import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { Header, Pagination } from "../components";
import { toast, ToastContainer } from "react-toastify";
import DefAvatar from "../data/avatar.png";
import NewPark from "../components/NewPark";
import ParkEdit from "../components/ParkEdit";
import DeletePark from "../components/DeletePark";
import { useStateContext } from "../contexts/ContextProvider";
import ParkView from "../components/ParkView";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "100%",
    height: "60%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Parks = () => {
  const [parks, setParks] = useState([]);
  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = parks.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(parks.length / recordsPerPage);

  const [selectedPark, setSelectedPark] = useState();

  const [parkEditIsOpen, setParkEditIsOpen] = useState(false);
  const [isNewParkOpen, setIsNewParkOpen] = useState(false);
  const [isParkViewOpen, setIsParkViewOpen] = useState(false);

  const closeModal = () => {
    setIsNewParkOpen(false);
    setParkEditIsOpen(false);
    setIsParkViewOpen(false);
  };

  useEffect(() => {
    getParkData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getParkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };
  const editPark = (park) => {
    setSelectedPark(park);
    setParkEditIsOpen(true);
  };

  const parkView = (park) => {
    setSelectedPark(park);
    setIsParkViewOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Parks" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewParkOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Park
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewParkOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Park"
      >
        <NewPark
          fetchData={getParkData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <Modal
        isOpen={parkEditIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Word"
      >
        <ParkEdit
          toastPopup={toastPopup}
          getParkData={getParkData}
          park={selectedPark}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isParkViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Park view"
      >
        <ParkView
          toastPopup={toastPopup}
          getParkData={getParkData}
          park={selectedPark}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Park Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Image</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  See More Link
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                parks.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={5} className="py-4 px-6 text-center justify-center">
                No Parks
              </td>
            </tr>
            {currentRecords.map((park) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      parkView(park);
                    }}
                  >
                    {park.park_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      parkView(park);
                    }}
                  >
                    {park.description}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    <img
                      src={
                        park.img_1_path === null ? DefAvatar : park.img_1_path
                      }
                      alt="park-img"
                      className="w-48"
                    />
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    <a
                      href={park.see_more_link}
                      target="_blank"
                      rel="noreferrer"
                      className="underline text-blue-600"
                    >
                      Link
                    </a>
                  </td>
                  <td>
                    <DeletePark
                      park_id={park.id}
                      getParkData={getParkData}
                      toastPopup={toastPopup}
                    />
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        editPark(park);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Parks;
