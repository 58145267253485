import { MdOutlineCancel } from "react-icons/md";

const ReservationView = (props) => {

  return (
    <div>
      {" "}
      {/* close button */}
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Reservation View</p>

        <button
          type="button"
          onClick={() => {
            props.closeModal(2);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex flex-row m-1">
        <div className=" w-1/2 m-1">
          <lable className="font-bold text-lg">Full Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.full_name}
            disabled
          />
        </div>

        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Email Address</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.email_address}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-row m-1">
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Contact Number</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.mobile_number}
            disabled
          />
        </div>
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Package Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.package_name}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-row m-1">
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Park Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.park_name}
            disabled
          />
        </div>
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Number Of Guests</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.num_of_guests}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-row m-1">
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">Start Date</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.start_date}
            disabled
          />
        </div>
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">End Date</lable>
          <input
            className="w-full"
            type="text"
            value={props.reservation.end_date}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
export default ReservationView;
