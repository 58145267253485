import axios from "axios";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { Header, Pagination } from "../components";
import { toast, ToastContainer } from "react-toastify";
import NewPackage from "../components/NewPackage";
import PackageEdit from "../components/PackageEdit";
import DeletePackage from "../components/DeletePackage";
import { useStateContext } from "../contexts/ContextProvider";
import PackageView from "../components/PackageView";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Packages = () => {
  const token = sessionStorage.getItem("token");
  const [packages, setPackages] = useState([]);
  const [parks, setParks] = useState([]);
  const { url } = useStateContext();

  //const for search bar
  const [parkId, setParkId] = useState(0);
  const [typeData, setTypeData] = useState(null);
  const [nationalityPackageId, setNationalityPackageId] = useState(0);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = packages.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(packages.length / recordsPerPage);

  const [isNewPackageOpen, setIsNewPackageOpen] = useState(false);
  const [isNewPackageEdit, setIsEditPackageOpen] = useState(false);
  const [isPackageViewOpen, setIsPackageViewOpen] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState();

  useEffect(() => {
    getPackageData(0, 0, null);
    getParkData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if(status === 2){
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getPackageData = async (parkId, nationalityPackageId, typeData) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      park_id: parkId,
      search_data: typeData,
      nationality_package_id: nationalityPackageId,
    };
    await axios
      .post(url + "/api/package/search", bodyParameters, config)

      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getParkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const closeModal = () => {
    setIsNewPackageOpen(false);
    setIsEditPackageOpen(false);
    setIsPackageViewOpen(false);
  };
  const editPackage = (pack) => {
    setSelectedPackage(pack);
    setIsEditPackageOpen(true);
  };
  const packageView = (pack) => {
    setSelectedPackage(pack);
    setIsPackageViewOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Packages" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewPackageOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Package
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewPackageOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Park"
      >
        <NewPackage
          getPackageData={getPackageData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <Modal
        isOpen={isNewPackageEdit}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Word"
      >
        <PackageEdit
          toastPopup={toastPopup}
          getPackageData={getPackageData}
          package={selectedPackage}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPackageViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="package view"
      >
        <PackageView
          toastPopup={toastPopup}
          getPackageData={getPackageData}
          package={selectedPackage}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {/* search bar */}
        <div className="flex flex-row">
          <div className="flex flex-col  w-1/3 m-2">
            <div>
              <lable className="font-bold">Park Name</lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={parkId}
                onChange={(e) => {
                  setParkId(e.target.value);
                  getPackageData(
                    e.target.value,
                    nationalityPackageId,
                    typeData
                  );
                }}
              >
                <option value={0} selected>
                  All Parks
                </option>
                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col  w-1/3 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Nationality Type </lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={nationalityPackageId}
                onChange={(e) => {
                  setNationalityPackageId(e.target.value);
                  getPackageData(parkId, e.target.value, typeData);
                }}
              >
                <option value={0} selected>
                  All
                </option>
                <option value={1}>Local</option>
                <option value={2}>Foreign</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col  w-1/3 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Type here</lable>
            </div>
            <div>
              <input
                className="w-full rounded-md border-2"
                type="text"
                value={typeData}
                onChange={(e) => {
                  setTypeData(e.target.value);
                  getPackageData(parkId, nationalityPackageId, e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* search bar end */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Park Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Package Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Per Person Amount
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Nationality Type
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                packages.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={6} className="py-4 px-6 text-center justify-center">
                No Packages
              </td>
            </tr>
            {currentRecords.map((pack) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      packageView(pack);
                    }}
                  >
                    {pack.park_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      packageView(pack);
                    }}
                  >
                    {pack.package_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      packageView(pack);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: pack.description,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      packageView(pack);
                    }}
                  >
                    {pack.per_person_amount}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {pack.nationality_package_id === "1" ? (
                      <div className="bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg">
                        Local
                      </div>
                    ) : (
                      <div className="bg-yellow-500  text-white text-center font-normal py-1 px-1 rounded-lg">
                        Forieng
                      </div>
                    )}
                  </td>
                  <td>
                    <DeletePackage
                      package_id={pack.package_id}
                      getPackageData={getPackageData}
                      toastPopup={toastPopup}
                      closeModal={closeModal}
                    />
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        editPackage(pack);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Packages;
