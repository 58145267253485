import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

const DeletePark = (props) => {
  const token = sessionStorage.getItem("token");
  const user_id = sessionStorage.getItem("id");
  const { url } = useStateContext();

  const deletePark = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      park_id: props.park_id,
      status: 0,
      updated_by: user_id,
    };

    await axios
      .post(url + "/api/park/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getParkData(token);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  return (
    <>
      <button
        onClick={deletePark}
        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl"
      >
        Delete
      </button>
    </>
  );
};
export default DeletePark;
