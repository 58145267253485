import React from "react";

const Footer = () => (
  <div className="fixed bottom-0 flex w-full">
    <p className="dark:text-gray-200 text-gray-700 text-center m-2">
      © 2023 All rights reserved by{" "}
      <a
        href="https://wildceylonadventures.lk"
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-500"
      >
        Wild Ceylon Adventures
      </a>{" "}
      | Developed by{" "}
      <a
        href="https://aventureit.com"
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-500"
      >
        {" "}
        Aventure IT Solution (Pvt) Ltd.
      </a>
    </p>
  </div>
);

export default Footer;
