import axios from "axios";
import { MdDelete } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const DeleteGallery = (props) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");

  const deleteGallery = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      gallery_id: props.gallery_id,
      status: 0,
    };

    await axios
      .post(url + "/api/gallery/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getGelleryData(token);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  return (
    <>
      <button
        onClick={deleteGallery}
        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-1 rounded-3xl"
      >
        <MdDelete/>
      </button>
    </>
  );
};
export default DeleteGallery;
