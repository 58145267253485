import React from "react";
import "tw-elements";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Footer, Sidebar } from "./components";
import {
  Dashboard,
  Galleries,
  Login,
  Packages,
  Parks,
  Support,
  Addons,
  Sliders,
  About,
  Reservations,
  Prices,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { currentMode, activeMenu } = useStateContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div
          className={
            window.location.pathname === "/login"
              ? "hidden"
              : "flex relative dark:bg-main-dark-bg"
          }
        >
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              <Routes>
                <Route path="" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/contacts" element={<Support />} />
                <Route path="/parks" element={<Parks />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/galleries" element={<Galleries />} />
                <Route path="/addons" element={<Addons />} />
                <Route path="/sliders" element={<Sliders />} />
                <Route path="/about" element={<About />} />
                <Route path="/reservations" element={<Reservations />} />
                <Route path="/pp-prices" element={<Prices />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
        <div
          className={
            window.location.pathname === "/login"
              ? "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
              : "hidden"
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<Login />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
