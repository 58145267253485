import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../components/Header";
import Pagination from "../components/Paginations";
import DeleteReservation from "../components/DeleteReservation";
import ReservationStatusLabel from "../components/ReservationStatusLable";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";
import ReservationView from "../components/ReservationView";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "100%",
    height: "65%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const [parks, setParks] = useState([]);
  const [packages, setPackages] = useState([]);
  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();

  //const for search bar
  const [parkId, setParkId] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [fullName, setFullName] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [email, setEmail] = useState(null);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = reservations.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(reservations.length / recordsPerPage);

  const [isReservationViewOpen, setIsReservationViewOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState();

  useEffect(() => {
    getReservationData(0, 0, null, null, null);
    getParkData();
    packageData();
  }, []);

  const getReservationData = async (
    parkId,
    packageId,
    fullName,
    contactNumber,
    email
  ) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      package_id: packageId,
      park_id: parkId,
      full_name: fullName,
      mobile_number: contactNumber,
      email_address: email,
    };

    await axios
      .post(url + "/api/reservation/search", bodyParameters, config)
      .then((response) => {
       if (response.data.success) {
          let newArray = response.data.output;
          setReservations(newArray.reverse());
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getParkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const packageData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/package/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const statusChange = async (e, reserve) => {
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (e.target.value == 1) {
      const bodyParameters = {
        status: 1,
        reservation_id: reserve.reservation_id,
      };

      await axios
        .post(url + "/api/reservation/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getReservationData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (e.target.value == -1) {
      const bodyParameters = {
        status: -1,
        reservation_id: reserve.reservation_id,
      };

      await axios
        .post(url + "/api/reservation/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getReservationData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (e.target.value == 0) {
      const bodyParameters = {
        status: 0,
        reservation_id: reserve.reservation_id,
      };

      await axios
        .post(url + "/api/reservation/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getReservationData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  const closeModal = () => {
    setIsReservationViewOpen(false);
  };

  const reservationView = (reserve) => {
    setSelectedReservation(reserve);
    setIsReservationViewOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Reservations" />
        </div>
      </div>

      <Modal
        isOpen={isReservationViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Reservation view"
      >
        <ReservationView
          toastPopup={toastPopup}
          getReservationData={getReservationData}
          reservation={selectedReservation}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {/* search bar */}
        <div className="flex flex-row">
          <div className="flex flex-col w-1/5 m-2">
            <div>
              <lable className="font-bold">Park Name</lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={parkId}
                onChange={(e) => {
                  setParkId(e.target.value);

                  getReservationData(
                    e.target.value,
                    packageId,
                    fullName,
                    contactNumber,
                    email
                  );
                }}
              >
                <option value={0} selected>
                  All Parks
                </option>
                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col w-1/5 m-2">
            <div>
              <lable className="font-bold">Package Name</lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={packageId}
                onChange={(e) => {
                  setPackageId(e.target.value);

                  getReservationData(
                    parkId,
                    e.target.value,
                    fullName,
                    contactNumber,
                    email
                  );
                }}
              >
                <option value={0} selected>
                  All Packages
                </option>
                {packages.map((pack) => {
                  return <option value={pack.id}>{pack.package_name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col  w-1/5 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Full Name</lable>
            </div>
            <div>
              <input
                className="w-full rounded-md border-2"
                type="text"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);

                  getReservationData(
                    parkId,
                    packageId,
                    e.target.value,
                    contactNumber,
                    email
                  );
                }}
              />
            </div>
          </div>

          <div className="flex flex-col  w-1/5 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Contact Number</lable>
            </div>
            <div>
              <input
                className="w-full rounded-md border-2"
                type="tel"
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);

                  getReservationData(
                    parkId,
                    packageId,
                    fullName,
                    e.target.value,
                    email
                  );
                }}
              />
            </div>
          </div>

          <div className="flex flex-col  w-1/5 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Email Address</lable>
            </div>
            <div>
              <input
                className="w-full rounded-md border-2"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);

                  getReservationData(
                    parkId,
                    packageId,
                    fullName,
                    contactNumber,
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* search bar end */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Full Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Email Address
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Mobile Number
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Nationality
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Package Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Guests</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Start date
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">End Date</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Addons</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Status</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                reservations.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={10} className="py-4 px-6 text-center justify-center">
                No Reservation Requests
              </td>
            </tr>
            {currentRecords.map((reserve) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.full_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.email_address}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.mobile_number}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {reserve.nationality_package_id === "1" ? (
                      <div className="bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg">
                        Local
                      </div>
                    ) : (
                      <div className="bg-yellow-500  text-white text-center font-normal py-1 px-1 rounded-lg">
                        Foreign
                      </div>
                    )}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.package_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.num_of_guests}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.start_date === null
                      ? "Not Selected Start Date"
                      : reserve.start_date}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.end_date === null
                      ? "Not Selected End Date"
                      : reserve.end_date}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      reservationView(reserve);
                    }}
                  >
                    {reserve.addon_ids}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    <ReservationStatusLabel status={reserve.status} />
                  </td>
                  <td>
                    <DeleteReservation
                      reservation_id={reserve.reservation_id}
                      getReservationData={getReservationData}
                      toastPopup={toastPopup}
                    />
                    &nbsp; &nbsp;
                    <select
                      className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center"
                      onChange={(e) => {
                        statusChange(e, reserve);
                      }}
                      value={reserve.status}
                    >
                      <option value={0}>Pending</option>
                      <option value={1}>Confirm</option>
                      <option value={-1}>Rejected</option>
                    </select>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Reservations;
