import axios from "axios";
import { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { MdOutlineCancel } from "react-icons/md";

const NewGallery = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [fileTypeId, setFileTypeId] = useState(0);
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);

  const addGalley = async (e) => {
    e.preventDefault();
    let data = new FormData();

    data.append("file_type_id", fileTypeId);
    data.append("gallery_image", file);
    data.append("file_extention", fileExtension);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "/api/gallery/add", data, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            console.log(response);
            props.toastPopup(1, response.data.message);
            props.getGalleryData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New Image</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={addGalley} id="myForm">
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                File <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                required
                accept="image/*,video*/"
                onChange={fileHandler}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                File Type <label className="text-red-600">*</label>
              </label>
              <select
                className="appearance-none p-2 block text-black border border-gray-200 rounded-lg leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 w-full"
                onChange={(e) => {
                  setFileTypeId(e.target.value);
                }}
              >
                <option value={0} selected disabled={true}>File Type</option>
                <option value={1}>Image</option>
                <option value={2}>Video</option>
              </select>
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NewGallery;
