import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const NewPackagePrice = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [parks, setParks] = useState([]);
  const [packages, setPackages] = useState([]);
  const [parkId, setParkId] = useState();
  const [nationality, setNationality] = useState("1");
  const [packageId, setPackageId] = useState();
  const [prices1, setPrices1] = useState();
  const [prices2, setPrices2] = useState();
  const [prices3, setPrices3] = useState();
  const [prices4, setPrices4] = useState();
  const [prices5, setPrices5] = useState();
  const [prices6, setPrices6] = useState();
  const [prices7, setPrices7] = useState();
  const [prices8, setPrices8] = useState();
  const [prices9, setPrices9] = useState();
  const [prices10, setPrices10] = useState();
  const [prices11, setPrices11] = useState();
  const [prices12, setPrices12] = useState();
  const [prices13, setPrices13] = useState();
  const [prices14, setPrices14] = useState();
  const [prices15, setPrices15] = useState();
  const [prices16, setPrices16] = useState();
  const [prices17, setPrices17] = useState();
  const [prices18, setPrices18] = useState();
  const [prices19, setPrices19] = useState();
  const [prices20, setPrices20] = useState();
  const [prices21, setPrices21] = useState();
  const [prices22, setPrices22] = useState();
  const [prices23, setPrices23] = useState();
  const [prices24, setPrices24] = useState();
  const [prices25, setPrices25] = useState();
  const [prices26, setPrices26] = useState();
  const [prices27, setPrices27] = useState();
  const [prices28, setPrices28] = useState();
  const [prices29, setPrices29] = useState();
  const [prices30, setPrices30] = useState();
  const [prices31, setPrices31] = useState();
  const [prices32, setPrices32] = useState();
  const [prices33, setPrices33] = useState();
  const [prices34, setPrices34] = useState();
  const [prices35, setPrices35] = useState();
  const [prices36, setPrices36] = useState();
  const [prices37, setPrices37] = useState();
  const [prices38, setPrices38] = useState();
  const [prices39, setPrices39] = useState();
  const [prices40, setPrices40] = useState();

  useEffect(() => {
    parkData();
    packageData();
  }, []);

  const parkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };
  const packageData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/package/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const addPrice = async (e) => {
    if (nationality == "1") {
      e.preventDefault();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        package_id: packageId,
        park_id: parkId,
        pp_p1: prices1,
        pp_p2: prices2,
        pp_p3: prices3,
        pp_p4: prices4,
        pp_p5: prices5,
        pp_p6: prices6,
        pp_p7: prices7,
        pp_p8: prices8,
        pp_p9: prices9,
        pp_p10: prices10,
        pp_p11: prices11,
        pp_p12: prices12,
        pp_p13: prices13,
        pp_p14: prices14,
      };

      await axios
        .post(url + "/api/per_person/price/add", bodyParameters, config)
        .then((response) => {
          if (response?.status === 200) {
            if (response.data.success) {
              props.toastPopup(1, response.data.message);
              props.getPackagePriceData();
              props.closeModal(1);
            } else {
              props.toastPopup(3, response.data.message);
            }
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        });
    } else {
      e.preventDefault();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        package_id: packageId,
        park_id: 0,
        pp_p1: prices1,
        pp_p2: prices2,
        pp_p3: prices3,
        pp_p4: prices4,
        pp_p5: prices5,
        pp_p6: prices6,
        pp_p7: prices7,
        pp_p8: prices8,
        pp_p9: prices9,
        pp_p10: prices10,
        pp_p11: prices11,
        pp_p12: prices12,
        pp_p13: prices13,
        pp_p14: prices14,
        pp_p15: prices15,
        pp_p16: prices16,
        pp_p17: prices17,
        pp_p18: prices18,
        pp_p19: prices19,
        pp_p20: prices20,
        pp_p21: prices21,
        pp_p22: prices22,
        pp_p23: prices23,
        pp_p24: prices24,
        pp_p25: prices25,
        pp_p26: prices26,
        pp_p27: prices27,
        pp_p28: prices28,
        pp_p29: prices29,
        pp_p30: prices30,
        pp_p31: prices31,
        pp_p32: prices32,
        pp_p33: prices33,
        pp_p34: prices34,
        pp_p35: prices35,
        pp_p36: prices36,
        pp_p37: prices37,
        pp_p38: prices38,
        pp_p39: prices39,
        pp_p40: prices40,
      };

      await axios
        .post(url + "/api/per_person/price/add", bodyParameters, config)
        .then((response) => {
          if (response?.status === 200) {
            if (response.data.success) {
              props.toastPopup(1, response.data.message);
              props.getPackagePriceData();
              props.closeModal(1);
            } else {
              props.toastPopup(3, response.data.message);
            }
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        });
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          New Package Price
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={addPrice} id="myForm">
          <div className="flex flex-row">
            <div className="flex flex-wrap -mx-3 mb-6 w-full">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Park Name
                </label>
                {nationality === "1" ? (
                  <select
                    className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-full"
                    onChange={(e) => {
                      setParkId(e.target.value);
                    }}
                    value={parkId}
                  >
                    <option selected>Select Park Name</option>
                    {parks.map((park) => {
                      return <option value={park.id}>{park.park_name}</option>;
                    })}
                  </select>
                ) : (
                  <label
                    className=" tracking-wide text-red-500 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Not Specific Parks For Foreing Packages
                  </label>
                )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 w-full">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Package Name
                </label>

                <select
                  className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-full"
                  onChange={(e) => {
                    setPackageId(e.target.value);
                  }}
                  value={packageId}
                >
                  <option selected>Select Package Name</option>
                  {packages.map((pack) => {
                    return <option value={pack.id}>{pack.package_name}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 w-full">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Nationality
                </label>

                <select
                  className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center w-full"
                  onChange={(e) => {
                    setNationality(e.target.value);
                  }}
                  value={nationality}
                >
                  <option selected>Select Package Name</option>
                  <option value={1}>Local</option>
                  <option value={2}>Foreign</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6 w-full">
            {nationality === "1" ? (
              //per person price add table for local packages
              <table className="table-auto rounded-lg">
                <thead>
                  <tr className="bg-gray-400 rounded-lg">
                    <th>Person Count</th>
                    <th>Price(LKR.)</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <input
                        className="w-ful"
                        type="number"
                        onChange={(e) => {
                          setPrices1(e.target.value);
                        }}
                        value={prices1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices2(e.target.value);
                        }}
                        value={prices2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices3(e.target.value);
                        }}
                        value={prices3}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices4(e.target.value);
                        }}
                        value={prices4}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices5(e.target.value);
                        }}
                        value={prices5}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices6(e.target.value);
                        }}
                        value={prices6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices7(e.target.value);
                        }}
                        value={prices7}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices8(e.target.value);
                        }}
                        value={prices8}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices9(e.target.value);
                        }}
                        value={prices9}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices10(e.target.value);
                        }}
                        value={prices10}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices11(e.target.value);
                        }}
                        value={prices11}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices12(e.target.value);
                        }}
                        value={prices12}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices13(e.target.value);
                        }}
                        value={prices13}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>
                      <input
                        className="w-full"
                        type="number"
                        onChange={(e) => {
                          setPrices14(e.target.value);
                        }}
                        value={prices14}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              //per person price add table for foreigne packages
              <table className="table-auto rounded-lg">
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <thead>
                      <tr className="bg-gray-400 rounded-lg">
                        <th>Person Count</th>
                        <th>Price($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <input
                            className="w-ful"
                            type="number"
                            onChange={(e) => {
                              setPrices1(e.target.value);
                            }}
                            value={prices1}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices2(e.target.value);
                            }}
                            value={prices2}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices4(e.target.value);
                            }}
                            value={prices4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices3(e.target.value);
                            }}
                            value={prices3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices5(e.target.value);
                            }}
                            value={prices5}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices6(e.target.value);
                            }}
                            value={prices6}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices7(e.target.value);
                            }}
                            value={prices7}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices8(e.target.value);
                            }}
                            value={prices8}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices9(e.target.value);
                            }}
                            value={prices9}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices10(e.target.value);
                            }}
                            value={prices10}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices11(e.target.value);
                            }}
                            value={prices11}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices12(e.target.value);
                            }}
                            value={prices12}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices13(e.target.value);
                            }}
                            value={prices13}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices14(e.target.value);
                            }}
                            value={prices14}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices15(e.target.value);
                            }}
                            value={prices15}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices16(e.target.value);
                            }}
                            value={prices16}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>17</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices17(e.target.value);
                            }}
                            value={prices17}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>18</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices18(e.target.value);
                            }}
                            value={prices18}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>19</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices19(e.target.value);
                            }}
                            value={prices19}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices20(e.target.value);
                            }}
                            value={prices20}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </div>

                  <div className="w-1/2">
                    <thead>
                      <tr className="bg-gray-400 rounded-lg">
                        <th>Person Count</th>
                        <th>Price($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>21</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices21(e.target.value);
                            }}
                            value={prices21}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>22</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices22(e.target.value);
                            }}
                            value={prices22}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>23</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices23(e.target.value);
                            }}
                            value={prices23}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>24</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices24(e.target.value);
                            }}
                            value={prices24}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>25</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices25(e.target.value);
                            }}
                            value={prices25}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>26</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices26(e.target.value);
                            }}
                            value={prices26}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>27</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices27(e.target.value);
                            }}
                            value={prices27}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>28</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices28(e.target.value);
                            }}
                            value={prices28}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>29</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices29(e.target.value);
                            }}
                            value={prices29}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                      <tr>
                        <td>30</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices30(e.target.value);
                            }}
                            value={prices30}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>31</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices31(e.target.value);
                            }}
                            value={prices31}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>32</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices32(e.target.value);
                            }}
                            value={prices32}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>33</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices33(e.target.value);
                            }}
                            value={prices33}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>34</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices34(e.target.value);
                            }}
                            value={prices34}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>35</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices35(e.target.value);
                            }}
                            value={prices35}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>36</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices36(e.target.value);
                            }}
                            value={prices36}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>37</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices37(e.target.value);
                            }}
                            value={prices37}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>38</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices38(e.target.value);
                            }}
                            value={prices38}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>39</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices39(e.target.value);
                            }}
                            value={prices39}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>40</td>
                        <td>
                          <input
                            className="w-full"
                            type="number"
                            onChange={(e) => {
                              setPrices40(e.target.value);
                            }}
                            value={prices40}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </div>
              </table>
            )}
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NewPackagePrice;
