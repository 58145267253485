import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Header } from "../components";
import DefAvatar from "../data/avatar.png";
import Modal from "react-modal";
import NewAbout from "../components/NewAbout";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "100%",
    height: "70%",
    width: "auto",
    maxWidth: "80%",
  },
};

const About = () => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [about, setAbout] = useState([]);
  const [isNewAboutOpen, setIsNewAboutOpen] = useState(false);

  const closeModal = () => {
    setIsNewAboutOpen(false);
  };

  useEffect(() => {
    getAboutData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getAboutData = async () => {
    const bodyParameters = {
      status: 1,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .post(url + "/api/about/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setAbout(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="About" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewAboutOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            Update About Us Page Details
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewAboutOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New About Detail"
      >
        <NewAbout
          getAboutData={getAboutData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Content</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Image</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {about.map((ab) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 cursor-pointer">
                    {ab.content_type_id === "1" ? (
                      <div className="bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg">
                        About Us
                      </div>
                    ) : ab.content_type_id === "2" ? (
                      <div className="bg-yellow-600  text-white text-center font-normal py-1 px-1 rounded-lg">
                        Vission
                      </div>
                    ) : (
                      <div className="bg-green-600  text-white text-center font-normal py-1 px-1 rounded-lg">
                        Misson
                      </div>
                    )}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: ab.title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: ab.description,
                    }}
                  ></td>
                  <td className="py-4 px-6 cursor-pointer">
                    <img
                      src={ab.file_path === null ? DefAvatar : ab.file_path}
                      alt="gallery-img"
                      className="w-60"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default About;
