import axios from "axios";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Logo from "../data/logo.png";
import { Puff } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

const Login = () => {
  const { url } = useStateContext();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));

  useEffect(() => {
    setTimeout(() => {
      if (token !== null) {
        window.location.replace("/dashboard");
      } else {
        setLoading(false);
      }
    }, 1000);
  }, []);

  const login = async (e) => {
    e.preventDefault();

    setLoading(true);

    const bodyParameters = {
      email_address: email,
      password: password,
      login_type: 2,
      os_type: 3,
      device_id: 1,
      push_id: 11,
    };

    await axios
      .post(url + "/api/login", bodyParameters)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            setError(null);
            setToken(response.data.output);
            sessionStorage.setItem("token", response.data.output);
            getUserData(response.data.output);
          } else {
            setError(response.data.message);
          }
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const getUserData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    await axios
      .post(url + "/api/auth/user", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          sessionStorage.setItem("user", response.data.output[0].full_name);
          sessionStorage.setItem("id", response.data.output[0].user_id);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      })
      .finally(() => {
        window.location.replace("/dashboard");
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <ToastContainer />
      {loading ? (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div class="mt-3 flex -space-x-20 overflow-hidden">
            <img
              class="inline-block h-20 w-20 rounded-full ring-2 ring-white"
              src={Logo}
              alt=""
            />
            <Puff
              height="80"
              width="80"
              radisu={1}
              color="#2f9dd7"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex flex-col items-center mb-6 text-3xl font-extrabold text-gray-900 dark:text-white">
            <img className="w-24" src={Logo} alt="logo" />
            <p className="text-3xl font-extrabold text-gray-900 dark:text-white">
              Wild Ceylon Adventures
            </p>
          </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 z-10">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <div className={``}></div>
              <form className="space-y-4 md:space-y-6" onSubmit={login}>
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                  />
                </div>
                <div>
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <a
                    href="/#"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </a>
                </div>

                {error === null ? (
                  <div></div>
                ) : (
                  <div className="text-sm text-red-600 text-center border-2 border-dashed">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default Login;
