import axios from "axios";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const AddonEdit = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();

  const [title, setTitle] = useState(props.addon.title);
  const [description, setDescription] = useState(props.addon.description);
  const [amountTypeId, setAmountTypeId] = useState(props.addon.amount_type_id);
  const [amount, setAmount] = useState(props.addon.amount);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [fileExtension1, setFileExtension1] = useState(null);
  const [fileExtension2, setFileExtension2] = useState(null);

  const editAddon = async (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append("addons_id", props.addon.id);
    data.append("title", title);
    data.append("description", description);
    data.append("amount_type_id", amountTypeId);
    data.append("amount", amount);
    data.append("img_1_path", file1);
    data.append("file_extention1", fileExtension1);
    data.append("img_2_path", file2);
    data.append("file_extention2", fileExtension2);
    data.append("status", 1);
    data.append("is_active", 1);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "/api/addons/edit", data, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getAddonData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
       props.toastPopup(2, error.message);
      });
  };

  const fileHandler1 = (e) => {
    setFile1(e.target.files[0]);
    setFileExtension1(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler2 = (e) => {
    setFile2(e.target.files[0]);
    setFileExtension2(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Edit AddOn</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={editAddon} id="myForm">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Title <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Title"
                required
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description <label className="text-red-600">*</label>
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Description"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Payment Type <label className="text-red-600">*</label>
              </label>
              <select
                className="appearance-none p-2 block text-black border border-gray-200 rounded-lg leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 w-full"
                onChange={(e) => {
                  setAmountTypeId(e.target.value);
                }}
                value={amountTypeId}
              >
                <option value={1}>Quantity Wise</option>
                <option value={2}>Person Wise</option>
              </select>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Price <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="number"
                placeholder="Amount"
                required
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image 1
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler1}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image 2
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler2}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddonEdit;
