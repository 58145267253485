import axios from "axios";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const NewPark = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();

  const [parkName, setParkName] = useState();
  const [description, setDescription] = useState();
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [seeMoreLink, setSeeMoreLink] = useState();
  const [fileExtension1, setFileExtension1] = useState(null);
  const [fileExtension2, setFileExtension2] = useState(null);
  const [fileExtension3, setFileExtension3] = useState(null);
  const [fileExtension4, setFileExtension4] = useState(null);

  const addPark = async (e) => {
    e.preventDefault();

    let data = new FormData();

    data.append("park_name", parkName);
    data.append("description", description);
    data.append("img_1_path", file1);
    data.append("file_extention1", fileExtension1);
    data.append("img_2_path", file2);
    data.append("file_extention2", fileExtension2);
    data.append("img_3_path", file3);
    data.append("file_extention3", fileExtension3);
    data.append("img_4_path", file4);
    data.append("file_extention4", fileExtension4);
    data.append("see_more_link", seeMoreLink);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "/api/park/add", data, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.fetchData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const fileHandler1 = (e) => {
    setFile1(e.target.files[0]);
    setFileExtension1(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler2 = (e) => {
    setFile2(e.target.files[0]);
    setFileExtension2(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler3 = (e) => {
    setFile3(e.target.files[0]);
    setFileExtension3(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler4 = (e) => {
    setFile4(e.target.files[0]);
    setFileExtension4(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New Park</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={addPark} id="myForm">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Park Name <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Park Name"
                required
                value={parkName}
                onChange={(e) => {
                  setParkName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description <label className="text-red-600">*</label>
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Description"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                required
                accept="image/*"
                onChange={fileHandler1}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Wikipedia Link <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Wikipedia Link"
                required
                value={seeMoreLink}
                onChange={(e) => {
                  setSeeMoreLink(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Optional Image 1
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler2}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Optional Image 2
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler3}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Optional Image 3
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler4}
              />
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NewPark;
