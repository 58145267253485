import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

const NewPackage = (props) => {
  const token = useState(sessionStorage.getItem("token"));
  const { url } = useStateContext();
  const [parks, setParks] = useState([]);

  const [packageName, setPackageName] = useState();
  const [parkId, setParkId] = useState(0);
  const [perPersonAmount, setPerPersonAmount] = useState();
  const [conditon, setCondition] = useState();
  const [features, setFeatures] = useState();
  const [nationalityPackageId, setNationalityPackageId] = useState(1);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [fileExtension1, setFileExtension1] = useState(null);
  const [fileExtension2, setFileExtension2] = useState(null);
  const [fileExtension3, setFileExtension3] = useState(null);

  useEffect(() => {
    parkData();
  }, []);
  const parkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const addPackage = async (e) => {
    e.preventDefault();
    const form = document.forms.myForm;
    const formData = new FormData(form);
    const description = formData.get("desc-value");

    let data = new FormData();

    data.append("package_name", packageName);
    data.append("description", description);
    data.append("park_id", parkId);
    data.append("per_person_amount", perPersonAmount);
    data.append("condition", conditon);
    data.append("features", features);
    data.append("nationality_package_id", nationalityPackageId);
    data.append("img_1_path", file1);
    data.append("file_extention1", fileExtension1);
    data.append("img_2_path", file2);
    data.append("file_extention2", fileExtension2);
    data.append("img_3_path", file3);
    data.append("file_extention3", fileExtension3);
    data.append("status", 1);
    data.append("is_active", 1);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "/api/package/add", data, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getPackageData();
            props.closeModal(1);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const fileHandler1 = (e) => {
    setFile1(e.target.files[0]);
    setFileExtension1(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler2 = (e) => {
    setFile2(e.target.files[0]);
    setFileExtension2(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler3 = (e) => {
    setFile3(e.target.files[0]);
    setFileExtension3(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New Package</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" onSubmit={addPackage} id="myForm">
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Package Name <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Package Name"
                required
                value={packageName}
                onChange={(e) => {
                  setPackageName(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Nationality Type <label className="text-red-600">*</label>
              </label>

              <select
                className="appearance-none p-2 block text-black border border-gray-200 rounded-lg leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 w-full"
                onChange={(e) => {
                  setNationalityPackageId(e.target.value);
                }}
                value={nationalityPackageId}
                required
              >
                <option value={1}>Local</option>
                <option value={2}>Foriegner</option>
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description <label className="text-red-600">*</label>
              </label>
              <RichTextEditorComponent
                id="desc"
                htmlAttributes={{ name: "desc-value" }}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div
              className={
                nationalityPackageId === 2 ? "hidden" : "visible w-full px-3"
              }
            >
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Park Name <label className="text-red-600">*</label>
              </label>

              <select
                className="appearance-none p-2 block text-black border border-gray-200 rounded-lg leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 w-full"
                onChange={(e) => {
                  setParkId(e.target.value);
                }}
                value={parkId}
              >
                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </select>
            </div>

            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Per Person Amount <label className="text-red-600">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="number"
                placeholder="Amount"
                required
                value={perPersonAmount}
                onChange={(e) => {
                  setPerPersonAmount(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Conditions
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Condition"
                value={conditon}
                onChange={(e) => {
                  setCondition(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Features
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Features"
                value={features}
                onChange={(e) => {
                  setFeatures(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 1
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler1}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 2
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler2}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 3
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler3}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NewPackage;
