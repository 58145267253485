import Modal from "react-modal";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Header, Pagination } from "../components";
import EditPackagePrice from "../components/EditPackagePrice";
import NewPackagePrice from "../components/NewPackagePrice";
import PerPersonPriceDelete from "../components/PerPersonPriceDelete";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Prices = () => {
  const [prices, setPrices] = useState([]);
  const [parks, setParks] = useState([]);
  const [packages, setPackages] = useState([]);

  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();

  const [packagePriceEditIsOpen, setPackagePriceEditIsOpen] = useState(false);
  const [isNewPackagePriceOpen, setIsNewPackagePriceOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState();

  //const for search bar
  const [parkId, setParkId] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [personCount, setPersonCount] = useState(1);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = prices.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(prices.length / recordsPerPage);

  useEffect(() => {
    getPackagePriceData(0, 0, 1);
    getParkData();
    packageData();
  }, []);

  const getPackagePriceData = async (packageId, parkId, personCount) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      package_id: packageId,
      park_id: parkId,
      person_count: personCount,
    };

    await axios
      .post(url + "/api/price/search", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setPrices(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getParkData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/park/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const packageData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/package/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const closeModal = () => {
    setIsNewPackagePriceOpen(false);
    setPackagePriceEditIsOpen(false);
  };

  const editPrice = (price) => {
    setSelectedPrice(price);
    setPackagePriceEditIsOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Per Person Prices" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewPackagePriceOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New PP Price
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewPackagePriceOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Park"
      >
        <NewPackagePrice
          getPackagePriceData={getPackagePriceData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <Modal
        isOpen={packagePriceEditIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Park"
      >
        <EditPackagePrice
          getPackagePriceData={getPackagePriceData}
          closeModal={closeModal}
          prices={selectedPrice}
          toastPopup={toastPopup}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {/* search bar */}
        <div className="flex flex-row ">
          <div className="flex flex-col  w-1/3 m-2">
            <div>
              <lable className="font-bold">Park Name</lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={parkId}
                onChange={(e) => {
                  setParkId(e.target.value);
                  getPackagePriceData(packageId, e.target.value, personCount);
                }}
              >
                <option value={0} selected>
                  All Parks
                </option>
                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col  w-1/3 m-2">
            <div>
              <lable className="font-bold">Package Name</lable>
            </div>
            <div>
              <select
                className="w-full rounded-md border-2 border-gray-400"
                value={packageId}
                onChange={(e) => {
                  setPackageId(e.target.value);
                  getPackagePriceData(e.target.value, parkId, personCount);
                }}
              >
                <option value={0} selected>
                  All Packages
                </option>
                {packages.map((pack) => {
                  return <option value={pack.id}>{pack.package_name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex flex-col  w-1/3 m-2 border-gray-400">
            <div>
              <lable className="font-bold">Heads Count</lable>
            </div>
            <div>
              <input
                className="w-full rounded-md border-2"
                type="number"
                min={1}
                max={14}
                value={personCount}
                onChange={(e) => {
                  setPersonCount(e.target.value);
                  getPackagePriceData(packageId, parkId, e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* search bar end */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Package Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Park Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Number Of Guests
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Per Person Price
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                prices.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={5} className="py-4 px-6 text-center justify-center">
                No Prices
              </td>
            </tr>
            {currentRecords.map((price) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 cursor-pointer">
                    {price.package_name}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {price.park_id === "0" ? (
                      <div className="bg-blue-600 text-black text-center font-normal py-1 px-1 rounded-lg">
                        Foreing Packages Haven't Specific Parks
                      </div>
                    ) : (
                      price.park_name
                    )}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {price.person_count}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">{price.pp_price}</td>
                  <td className="py-4 px-6 cursor-pointer">
                    <PerPersonPriceDelete
                      ppp_id={price.per_person_price_id}
                      getPackagePriceData={getPackagePriceData}
                      toastPopup={toastPopup}
                    />
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        editPrice(price);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Prices;
