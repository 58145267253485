import React from "react";
import {
  FiBarChart
} from "react-icons/fi";
import {
  BsBoxSeam
} from "react-icons/bs";
import {
  MdAutoAwesomeMosaic,
  MdBackupTable,
  MdBookOnline,
  MdCached,
  MdHome,
  MdMap,
  MdOutlineSupervisorAccount,
  MdOutlineSupportAgent,
  MdPhotoAlbum,
  MdPriceChange,
  MdSlideshow,
} from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";


export const links = [
  {
    title: "",
    links: [
      {
        view: "dashboard",
        name: "dashboard",
        icon: <MdAutoAwesomeMosaic />,
      },
    ],
  },
  {
    title: "Pages",
    links: [
      {
        view: "reservations",
        name: "reservations",
        icon: <MdBookOnline />,
      },
      {
        view: "packages",
        name: "packages",
        icon: <MdBackupTable />,
      },
      {
        view: "addons",
        name: "addons",
        icon: <MdCached />,
      },
      {
        view: "Per Person Prices",
        name: "pp-prices",
        icon: <MdPriceChange />,
      },
      {
        view: "contacts",
        name: "contacts",
        icon: <MdOutlineSupportAgent />,
      },
    ],
  },
  {
    title: "Content",
    links: [
      {
        view: "sliders",
        name: "sliders",
        icon: <MdSlideshow />,
      },
      {
        view: "parks",
        name: "parks",
        icon: <MdMap />,
      },
      {
        view: "galleries",
        name: "galleries",
        icon: <MdPhotoAlbum />,
      },
      {
        view: "about us",
        name: "about",
        icon: <MdHome />,
      },
    ]
  }
];

export const earningData = [
  {
    icon: <MdOutlineSupervisorAccount />,
    amount: "39,354",
    percentage: "-4%",
    title: "Customers",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "red-600",
  },
  {
    icon: <BsBoxSeam />,
    amount: "4,396",
    percentage: "+23%",
    title: "Products",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
    pcColor: "green-600",
  },
  {
    icon: <FiBarChart />,
    amount: "423,39",
    percentage: "+38%",
    title: "Sales",
    iconColor: "rgb(228, 106, 118)",
    iconBg: "rgb(255, 244, 229)",

    pcColor: "green-600",
  },
  {
    icon: <HiOutlineRefresh />,
    amount: "39,354",
    percentage: "-12%",
    title: "Refunds",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
    pcColor: "red-600",
  },
];
