import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Header, Pagination } from "../components";
import DefAvatar from "../data/avatar.png";
import Modal from "react-modal";
import NewSlider from "../components/NewSlider";
import DeleteSlider from "../components/DeleteSlider";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "100%",
    height: "70%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Sliders = () => {
  const [sliders, setSliders] = useState([]);
  const { url } = useStateContext();
  const [isNewSliderOpen, setIsNewSliderOpen] = useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sliders.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(sliders.length / recordsPerPage);

  const closeModal = () => {
    setIsNewSliderOpen(false);
  };

  useEffect(() => {
    getSliderData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getSliderData = async () => {
    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/sliders/all", bodyParameters)
      .then((response) => {
        if (response.data.success) {
          setSliders(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Sliders" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewSliderOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Slider
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewSliderOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Slider"
      >
        <NewSlider
          getSliderData={getSliderData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  File Type
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">File</div>
              </th>

              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                sliders.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={5} className="py-4 px-6 text-center justify-center">
                No Sliders
              </td>
            </tr>
            {currentRecords.map((slider) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: slider.title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: slider.description,
                    }}
                  ></td>
                  <td className="py-4 px-6 cursor-pointer">
                    {slider.file_type_id === "1" ? (
                      <div className="bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg w-full">
                        Image
                      </div>
                    ) : (
                      <div className="bg-yellow-500  text-white text-center font-normal py-1 px-1 rounded-lg w-full">
                        Video
                      </div>
                    )}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    <img
                      src={
                        slider.file_path === null ? DefAvatar : slider.file_path
                      }
                      alt="gallery-img"
                      className="w-48"
                    />
                  </td>

                  <td>
                    <DeleteSlider
                      slider_id={slider.id}
                      getSliderData={getSliderData}
                      toastPopup={toastPopup}
                    />
                    &nbsp;
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Sliders;
