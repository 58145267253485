import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Header } from "../components";
import DefAvatar from "../data/avatar.png";
import Modal from "react-modal";
import NewGallery from "../components/NewGallery";
import DeleteGallery from "../components/DeleteGallery";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "100%",
    height: "50%",
    width: "50%",
    maxWidth: "80%",
  },
};

const Galleries = () => {
  const [galleries, setGalleries] = useState([]);
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");

  const [isNewGalleryOpen, setIsNewGalleryOpen] = useState(false);

  const closeModal = () => {
    setIsNewGalleryOpen(false);
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getGalleryData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/gallery/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setGalleries(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Galleries" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewGalleryOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Image
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewGalleryOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Park"
      >
        <NewGallery
          getGalleryData={getGalleryData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg pb-4">
        <div class="l-wrap">
          <div class="grid grid-cols-4 gap-4">
            {galleries.map((image) => {
              if (image.file_type_id === '1') {
                return (
                  <div class="relative grid-item">
                    <img
                      src={image.file_path === null ? DefAvatar : image.file_path}
                      alt="gallery-img"
                    />
                    <div className="absolute top-1 right-1">
                      <DeleteGallery
                        gallery_id={image.id}
                        getGelleryData={getGalleryData}
                        toastPopup={toastPopup}
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div class="relative grid-item">
                    <video width="320" height="240" controls
                    >
                      <source src={image.file_path} type="video/mp4"></source>
                    </video>
                    <div className="absolute top-1 right-1">
                      <DeleteGallery
                        gallery_id={image.id}
                        getGelleryData={getGalleryData}
                        toastPopup={toastPopup}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Galleries;
