import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

const DeletePPPrice = (props) => {
  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();

  const deletePPPrice = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      per_person_price_id: props.ppp_id,
      is_active: 0,
    };

    await axios
      .post(url + "/api/price/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getPackagePriceData(token);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  return (
    <>
      <button
        onClick={deletePPPrice}
        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl"
      >
        Delete
      </button>
    </>
  );
};
export default DeletePPPrice;
