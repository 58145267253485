import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { FiBarChart } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import { earningData } from "../data/dummy";

const Dashboard = () => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));
  const [reservationCount, setReservationCount] = useState();
  const [packageCount, setPackageCount] = useState();
  const [addonCount, setAddonCount] = useState();
  const [supportCount, setSupportCount] = useState();

  useEffect(() => {
    if (token[0] === null) {
      window.location.replace("/login");
    }
    getReservationCount();
    getPackagesCount();
    getAddonCount();
    getSupportCount();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  const getReservationCount = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/reservation/count", bodyParameters, config)
      .then((response) => {
        setReservationCount(response.data.output[0].count);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getPackagesCount = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/package/count", bodyParameters, config)
      .then((response) => {
        setPackageCount(response.data.output[0].count);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getAddonCount = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/addon/count", bodyParameters, config)
      .then((response) => {
        setAddonCount(response.data.output[0].count);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const getSupportCount = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(url + "/api/support/count", bodyParameters, config)
      .then((response) => {
        setSupportCount(response.data.output[0].count);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  return (
    <div className="mt-24">
      <div className="flex flex-row">
        <div className=" w-1/4 rounded-lg border-2 border-gray-300 m-1 p-1">
          <lable className="font-bold text-lg">Reservations</lable>
          <MdOutlineSupervisorAccount />
          <p className="mt-3">
            <span className="text-lg font-semibold">{reservationCount}</span>
          </p>
        </div>

        <div className=" w-1/4 rounded-lg border-2 border-gray-300 m-1 p-1">
          <lable className="font-bold text-lg">Packages</lable>
          <BsBoxSeam />
          <p className="mt-3">
            <span className="text-lg font-semibold">{packageCount}</span>
          </p>
        </div>

        <div className=" w-1/4  rounded-lg border-2 border-gray-300 m-1 p-1">
          <lable className="font-bold text-lg">Supports</lable>
          <FiBarChart />
          <p className="mt-3">
            <span className="text-lg font-semibold">{supportCount}</span>
          </p>
        </div>

        <div className=" w-1/4  rounded-lg border-2 border-gray-300 m-1 p-1">
          <lable className="font-bold text-lg">Addons</lable>
          <HiOutlineRefresh />
          <p className="mt-3">
            <span className="text-lg font-semibold">{addonCount}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
