import { MdOutlineCancel } from "react-icons/md";

const PackageView = (props) => {
  return (
    <div>
      {" "}
      {/* close button */}
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Package View</p>

        <button
          type="button"
          onClick={() => {
            props.closeModal(2);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex flex-col m-">
        <div className=" w-full m-1">
          <lable className="font-bold text-lg">Package Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.package.package_name}
            disabled
          />
        </div>
        <div className="w-full m-1">
          <lable className="font-bold text-lg">Description</lable>
          <p
            dangerouslySetInnerHTML={{
              __html: props.package.description,
            }}
          ></p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/2">
          <lable className="font-bold text-lg">Park Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.package.park_name}
            disabled
          />
        </div>
        <div className="w-1/2">
          <lable className="font-bold text-lg">Per Person Amount(LKR.)</lable>
          <input
            className="w-full"
            type="text"
            value={props.package.per_person_amount}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div
          className={
            props.package.img_1_path === null ? "hidden" : "visible w-1/3 m-1"
          }
        >
          <lable className="font-bold text-lg">Image 1</lable>
          <img
            className="w-full h-full  rounded-md "
            src={props.package.img_1_path}
            alt="img"
          />
        </div>
        <div
          className={
            props.package.img_2_path === null ? "hidden" : "visible w-1/3 m-1"
          }
        >
          <lable className="font-bold text-lg">Image 2</lable>
          <img
            className="w-full h-full  rounded-md  "
            src={props.package.img_2_path}
            alt="img"
          />
        </div>
        <div
          className={
            props.package.img_3_path === null ? "hidden" : "visible w-1/3 m-1"
          }
        >
          <lable className="font-bold text-lg">Image 3</lable>
          <img
            className="w-full h-full   rounded-md "
            src={props.package.img_3_path}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};
export default PackageView;
