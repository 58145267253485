const ReservationStatusLabel = (props) => {
  if (props.status === "1") {
    return (
      <div className="bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg">
        Confirm
      </div>
    );
  }else if(props.status === "0") {
    return (
      <div className="bg-yellow-500  text-white text-center font-normal py-1 px-1 rounded-lg">
        Pending
      </div>
    );
  }else{
    return (
      <div className="bg-red-500  text-white text-center font-normal py-1 px-1 rounded-lg">
        Rejected
      </div>
    );
  }
};
export default ReservationStatusLabel;
