import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

const DeleteSlider = (props) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");

  const deleteSlider = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      slider_id: props.slider_id,
      status: 0,
    };

    await axios
      .post(url + "/api/sliders/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.getSliderData(token);
          } else {
            props.toastPopup(3, response.data.message);
          }
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  return (
    <>
      <button
        onClick={deleteSlider}
        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl"
      >
        Delete
      </button>
    </>
  );
};
export default DeleteSlider;
