import { MdOutlineCancel } from "react-icons/md";

const ParkView = (props) => {
  return (
    <div>
      {" "}
      {/* close button */}
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Park View</p>

        <button
          type="button"
          onClick={() => {
            props.closeModal(2);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex flex-row">
        <div className=" w-1/2 m-1">
          <lable className="font-bold text-lg">Park Name</lable>
          <input
            className="w-full"
            type="text"
            value={props.park.park_name}
            disabled
          />
        </div>
        <div className="w-1/2 m-1">
          <lable className="font-bold text-lg">See More Link</lable>
          <input
            className="w-full"
            type="text"
            value={props.park.see_more_link}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-full m-1">
          <lable className="font-bold text-lg">Description</lable>
          <p
            dangerouslySetInnerHTML={{
              __html: props.park.description,
            }}
          ></p>
        </div>
        <div className="flex flex-row">
          <div
            className={
              props.park.img_1_path === null ? "hidden" : "visible w-1/2 m-1"
            }
          >
            <lable className="font-bold text-lg">Image 1</lable>
            <img
              className="w-full h-full   rounded-md "
              src={props.park.img_1_path}
              alt="img"
            />
          </div>
          <div
            className={
              props.park.img_2_path === null ? "hidden" : "visible w-1/2 m-1"
            }
          >
            <lable className="font-bold text-lg">Image 2</lable>
            <img
              className="w-full h-full   rounded-md "
              src={props.park.img_2_path}
              alt="img"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div
            className={
              props.park.img_3_path === null ? "hidden" : "visible w-1/2 m-1"
            }
          >
            <lable className="font-bold text-lg">Image 3</lable>
            <img
              className="w-full h-full   rounded-md "
              src={props.park.img_3_path}
              alt="img"
            />
          </div>
          <div
            className={
              props.park.img_4_path === null ? "hidden" : "visible w-1/2 m-1"
            }
          >
            <lable className="font-bold text-lg">Image 4</lable>
            <img
              className="w-full h-full   rounded-md "
              src={props.park.img_4_path}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParkView;
