import { toast, ToastContainer } from "react-toastify";
import Header from "../components/Header";
import { useState } from "react";
import Pagination from "../components/Paginations";
import { useEffect } from "react";
import axios from "axios";
import DeleteSupport from "../components/DeleteSupport";
import SupportStatusLabel from "../components/SupportStatusLable";
import { useStateContext } from "../contexts/ContextProvider";

const Support = () => {
  const [supports, setSupports] = useState([]);
  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = supports.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(supports.length / recordsPerPage);

  useEffect(() => {
    getSupportData();
  }, []);

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  const getSupportData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "/api/support/view", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setSupports(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const statusChange = async (e, support) => {
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (e.target.value === "1") {
      const bodyParameters = {
        status: 1,
        user_id: sessionStorage.getItem("id"),
        support_id: support.id,
      };

      await axios
        .post(url + "/api/support/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getSupportData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (e.target.value === "-1") {
      const bodyParameters = {
        status: -1,
        user_id: sessionStorage.getItem("id"),
        support_id: support.id,
      };

      await axios
        .post(url + "/api/support/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getSupportData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (e.target.value === "0") {
      const bodyParameters = {
        status: 0,
        user_id: sessionStorage.getItem("id"),
        support_id: support.id,
      };

      await axios
        .post(url + "/api/support/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getSupportData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Contacts" />
        </div>
      </div>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Full Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Email Address
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Mobile Number
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>

              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Status</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                supports.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={7} className="py-4 px-6 text-center justify-center">
                No Contacts
              </td>
            </tr>
            {currentRecords.map((support) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 cursor-pointer">
                    {support.full_name}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {support.email_address}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {support.mobile_number}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">{support.title}</td>
                  <td className="py-4 px-6 cursor-pointer">
                    {support.description}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    <SupportStatusLabel status={support.status} />
                  </td>
                  <td className="flex space-x-2 py-4 px-6 text-center justify-center">
                    <DeleteSupport
                      support_id={support.id}
                      getSupportData={getSupportData}
                      toastPopup={toastPopup}
                    />
                    <select
                      className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center"
                      onChange={(e) => {
                        statusChange(e, support);
                      }}
                      value={support.status}
                    >
                      <option value={0}>Ongoing</option>
                      <option value={1}>Completed</option>
                      <option value={-1}>Rejected</option>
                    </select>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Support;
