import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";

const AddonView = (props) => {
  const addonTitle = useState(props.addon.title);
  const description = useState(props.addon.description);

  return (
    <div>
      {/* close button */}
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Addon View</p>

        <button
          type="button"
          onClick={() => {
            props.closeModal(2);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex flex-col m-">
        <div className=" w-full m-1">
          <lable className="font-bold text-lg">Addon Title</lable>
          <input
            className="w-full"
            type="text"
            value={props.addon.title}
            disabled
          />
        </div>
        <div className="w-full m-1">
          <lable className="font-bold text-lg">Addon Description</lable>
          <input
            className="w-full"
            type="text"
            value={props.addon.description}
            disabled
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div
          className={
            props.addon.img_1_path === null ? "hidden" : "visible w-1/2 m-1"
          }
        >
          <lable className="font-bold text-lg">Image 1</lable>
          <img
            className="w-full h-full   rounded-md  "
            src={props.addon.img_1_path}
            alt="img"
          />
        </div>
        <div
          className={
            props.addon.img_2_path === null ? "hidden" : "visible w-1/2 m-1"
          }
        >
          <lable className="font-bold text-lg">Image 2</lable>
          <img
            className="w-full h-full   rounded-md "
            src={props.addon.img_2_path}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};
export default AddonView;
